export default (data = {}) => {
    return {
        docType: [
            {
              id: 1,
              group: "Caja",
              label: "Comprobante de Ingreso",
              name: "Comprobante de Ingreso",
              description: "Gestión de pedidos de clientes",
              value: "CIN",
              to: "Recibido de",
              concept: "Por concepto de:",
              paymode: "Forma de pago",
              credits: "2",
              db: null,
              sign: true,
              fp: [
                {
                  codigo: "01",
                  valor: "Efectivo",
                },
                {
                  codigo: "02",
                  valor: "Transferencia",
                },
                {
                  codigo: "03",
                  valor: "Cuenta por pagar",
                },
              ],
            },
            {
              id: 2,
              group: "Caja",
              label: "Comprobante de Egreso",
              name: "Comprobante de Egreso",
              description: "Gestión de pedidos de clientes",
              value: "CEG",
              to: "Pagado a",
              concept: "Por concepto de:",
              paymode: "Pagado a",
              db: null,
              sign: true,
              fp: [
                {
                  codigo: "01",
                  valor: "Efectivo",
                },
                {
                  codigo: "02",
                  valor: "Transferencia",
                },
                {
                  codigo: "03",
                  valor: "Cuenta por Cobrar",
                },
              ],
            },
            {
              id: 3,
              group: "Caja",
              label: "Orden de Compra - Cliente",
              name: "Comprobante de Ingreso",
              description: "Gestión de pedidos de clientes",
              value: "OR",
              to: "Cliente",
              concept: "Productos / Servicios",
              paymode: "Forma de pago",
              db: 'Clientes',
              sign: false,
              fp: [
                {
                  codigo: "01",
                  valor: "Efectivo",
                },
                {
                  codigo: "02",
                  valor: "Transferencia",
                },
                {
                  codigo: "03",
                  valor: "Cuenta por pagar",
                },
              ],
            },
            {
              id: 4,
              group: "Ventas",
              label: "Remisión - Cliente",
              name: "Comprobante de Ingreso",
              description: "Gestión de pedidos de clientes",
              value: "REC",
              to: "Destino",
              concept: "Productos / Servicios",
              paymode: "Forma de pago",
              db: 'Clientes',
              sign: false,
              fp: [
                {
                  codigo: "01",
                  valor: "Efectivo",
                },
                {
                  codigo: "02",
                  valor: "Transferencia",
                },
                {
                  codigo: "03",
                  valor: "Cuenta por pagar",
                },
              ],
            },
            {
              id: 50,
              group: "Compras",
              label: "Orden de Compra - Proveedor",
              name: "Registro de ordenes de compra a proveedores",
              description: "Gestión de pedidos de Proveedores",
              value: "ORP",
              to: "Proveedor",
              concept: "Producto / Servicio",
              paymode: "Forma de pago",
              db: 'Proveedores',
              sign: false,
              fp: [
                {
                  codigo: "01",
                  valor: "Efectivo",
                },
                {
                  codigo: "02",
                  valor: "Transferencia",
                },
                {
                  codigo: "03",
                  valor: "Cuenta por pagar",
                },
              ],
              template_to: 'INV_IN',
            },
            {
              id: 51,
              group: "Inventario",
              label: "Ingreso de Mercancia",
              name: "Ingreso de Mercancia",
              description: "Ingreso de Mercancia",
              value: "I_IN",
              to: "Bodega",
              concept: "Producto / Servicio",
              paymode: "Concepto",
              db: 'suppliers',
              sign: false,
              fp: [
                {
                  codigo: "01",
                  valor: "Efectivo",
                },
                {
                  codigo: "02",
                  valor: "Transferencia",
                },
                {
                  codigo: "03",
                  valor: "Cuenta por pagar",
                },
              ],
            },
            {
              id: 52,
              group: "Inventario",
              label: "Salida de Mercancia",
              name: "Salida de Mercancia",
              description: "Salida de Mercancia",
              value: "I_OUT",
              to: "Bodega",
              concept: "Producto / Servicio",
              paymode: "Concepto",
              db: 'suppliers',
              sign: false,
              fp: [
                {
                  codigo: "01",
                  valor: "Efectivo",
                },
                {
                  codigo: "02",
                  valor: "Transferencia",
                },
                {
                  codigo: "03",
                  valor: "Cuenta por pagar",
                },
              ],
            },
            {
              id: 7,
              group: "Ventas",
              label: "Factura Electrónica",
              name: "Facturación Electrónica",
              description: "DIAN - Facturas Electrónicas",
              value: "FE",
              to: "Cliente",
              concept: "Producto / Servicio",
              paymode: "Forma de pago",
              db: 'Clientes',
              sign: false,
              fp: [
                {
                  codigo: "01",
                  valor: "Efectivo",
                },
                {
                  codigo: "02",
                  valor: "Transferencia",
                },
                {
                  codigo: "03",
                  valor: "Cuenta por pagar",
                },
              ],
            },
            {
              id: 8,
              group: "Ventas",
              label: "SISTEMA POS",
              name: "Punto de Venta",
              description: "Registro punto de venta",
              value: "POS",
              to: "Cliente",
              db: 'Clientes',
              sign: false,
              concept: "Producto / Servicio",
              paymode: "Forma de pago",
              fp: [
                {
                  codigo: "10",
                  valor: "Efectivo",
                },
                {
                  codigo: "48",
                  valor: "Tarjeta Crédito",
                },
                {
                  codigo: "49",
                  valor: "Tarjeta Débito",
                },
                {
                  codigo: "03",
                  valor: "Cuenta por Cobrar",
                },
              ],
            },
  
          ],
        ...data
    }
}
